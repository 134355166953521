
















































import Vue from "vue";
import Component from "vue-class-component";
import apiClient from "@/utilities/ApiClient";
import utils from "@/utilities/Utils";
import ApiButton from "@/vue/components/ApiButton.vue";
import { WashcareSymbolCode } from "@/model/WashcareSymbolCode";
import { ISaveResponse } from "@/model/ISaveResponse";
import { Ref } from "vue-property-decorator";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class WashcareSymbolDialogue extends Vue {

    //
    // -- properties
    //

    @Ref("codeForm") private readonly codeForm!: VForm;  

    private showDialogue: boolean = false;
    private dialogueTitle: string = "";
    private code: WashcareSymbolCode = new WashcareSymbolCode();
    private ApiClient = apiClient;

    //
    // -- methods
    //

    async edit(id: number): Promise<void> {
        this.reset();
        this.dialogueTitle = "Edit Washcare Symbol";
        const responseData = await apiClient.get(`api/translation/loadWashcareSymbol?id=${id}`);
        this.code.update(responseData);
        this.showDialogue = true;
    }

    private reset() {
        utils.resetObject(this.code);
        this.codeForm?.resetValidation();
    }

    private async save() {
        if (!this.codeForm.validate()) {
            toastr.warning("Please fix highlighted issues", "Cannot Save");
            return;
        }

        const saveResponse: ISaveResponse = await apiClient.post("/api/translation/saveWashcareSymbol", this.code, "code-save");
        if (saveResponse.isSuccess) {
            toastr.success("Saved");
            this.$emit("refresh");
            this.showDialogue = false;
        } else {
            toastr.warning("Failed to Save");
        }
        
    }
}
