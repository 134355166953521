




























import Vue from "vue";
import Component from "vue-class-component";
import ApiButton from "@/vue/components/ApiButton.vue";
import apiClient from "@/utilities/ApiClient";
import WashcareSymbolDialogue from "@/vue/components/dialogues/WashcareSymbolDialogue.vue";
import { WashcareSymbolCode, IWashcareSymbolCode } from "@/model/WashcareSymbolCode";

@Component({
    components: { 
        ApiButton,
        WashcareSymbolDialogue
    }
})

export default class WashSymbols extends Vue {

    async mounted(): Promise<void> { 
        this.loadSymbols();
    }
    
    //
    // -- properties
    //

    private symbolHeaders = [
        { text: "Preview", value: "id", sortable: false },
        { text: "Code", value: "fontCode", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Archived", value: "archived", sortable: false },
        { text: "Created", value: "createdDate", sortable: false },
        { text: "Last Updated", value: "lastUpdatedDate", sortable: false }
    ];

    private ApiClient = apiClient;
    private symbolList: Array<WashcareSymbolCode> = [];

    //
    // -- methods
    //

    private async loadSymbols() {
        this.symbolList = [];
        const response = await apiClient.get("/api/translation/searchWashcareSymbols");
        this.symbolList.push(...response.map((c: IWashcareSymbolCode) => new WashcareSymbolCode(c)));
    }

    edit(symbol: WashcareSymbolCode): void {
        const dialog: WashcareSymbolDialogue = this.$refs.washcareSymbolDialogue as WashcareSymbolDialogue;
        dialog.edit(symbol.id);
    }
}
