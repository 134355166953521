var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('div',{staticClass:"text-h4"},[_vm._v("Washcare Symbols")]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.symbolHeaders,"items":_vm.symbolList,"disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item.fontCode == '')?_c('v-icon',[_vm._v("mdi-close")]):_vm._e(),(item.fontCode)?_c('v-img',{staticStyle:{"border-style":"solid","cursor":"pointer","height":"38px","width":"38px"},attrs:{"src":_vm.ApiClient.resolveUrl('washcare/' + item.fontCode + '.gif')}}):_vm._e()]}},{key:"item.archived",fn:function(ref){
var item = ref.item;
return [(item.archived)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),(!item.archived)?_c('v-icon',[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateText")(item.createdDate)))]}},{key:"item.lastUpdatedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("dateText")(item.lastUpdatedDate)))]}}],null,true)}),_c('washcare-symbol-dialogue',{ref:"washcareSymbolDialogue",on:{"refresh":_vm.loadSymbols}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }